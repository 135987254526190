// Generated by Framer (8289486)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {cTzs2s5S8: {hover: true}};

const cycleOrder = ["cTzs2s5S8"];

const variantClassNames = {cTzs2s5S8: "framer-v-9c0mlm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "cTzs2s5S8", title: QeRUB9eiI = "Pages", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cTzs2s5S8", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-BP5Af", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-9c0mlm", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cTzs2s5S8"} ref={ref} style={{borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} transition={transition} {...addPropertyOverrides({"cTzs2s5S8-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7RE0gU2Fucy01MDA=", "--framer-font-family": "\"DM Sans\", sans-serif", "--framer-font-weight": "500", "--framer-letter-spacing": "0.25px", "--framer-line-height": "140%", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Pages</motion.p></React.Fragment>} className={"framer-1dl67sv"} fonts={["GF;DM Sans-500"]} layoutDependency={layoutDependency} layoutId={"WQGUixiNu"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={QeRUB9eiI} transition={transition} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-13xx0gr-container"} layoutDependency={layoutDependency} layoutId={"ZrvjdOOnC-container"} transition={transition}><Material color={"var(--token-f82457c5-8438-48a1-bcce-dde3448a0d3a, rgb(255, 255, 255)) /* {\"name\":\"icon/inverse\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"ExpandMore"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"ZrvjdOOnC"} layoutId={"ZrvjdOOnC"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-BP5Af [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BP5Af .framer-1kyuw7z { display: block; }", ".framer-BP5Af .framer-9c0mlm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: 42px; justify-content: center; overflow: visible; padding: 0px 12px 0px 16px; position: relative; width: min-content; }", ".framer-BP5Af .framer-1dl67sv { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-BP5Af .framer-13xx0gr-container { flex: none; height: 16px; position: relative; width: 16px; }", ".framer-BP5Af .framer-v-9c0mlm .framer-9c0mlm { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BP5Af .framer-9c0mlm { gap: 0px; } .framer-BP5Af .framer-9c0mlm > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-BP5Af .framer-9c0mlm > :first-child { margin-left: 0px; } .framer-BP5Af .framer-9c0mlm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 93.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"rhyGHhnqp":{"layout":["auto","fixed"]}}}
 * @framerVariables {"QeRUB9eiI":"title"}
 */
const FramerBJ12xDOPY: React.ComponentType<Props> = withCSS(Component, css, "framer-BP5Af") as typeof Component;
export default FramerBJ12xDOPY;

FramerBJ12xDOPY.displayName = "Navbar Menu Dropdown";

FramerBJ12xDOPY.defaultProps = {height: 42, width: 93.5};

addPropertyControls(FramerBJ12xDOPY, {QeRUB9eiI: {defaultValue: "Pages", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerBJ12xDOPY, [{family: "DM Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/BJ12xDOPY:default", url: "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf", weight: "500"}, ...MaterialFonts])